import './Page.scss';

interface PageProps {
    header?: JSX.Element | JSX.Element[]
    children?: JSX.Element | JSX.Element[]
    footer?: JSX.Element | JSX.Element[]
    className?: string;
}

function Page(props: PageProps): ReturnType<React.FC<PageProps>> {
    const { header, children, footer, className } = props
    return (
        <div className={`page ${className}`}>
            <header className="page-header">
                {header}
            </header >
            <div className='page-content'>
                {children}
            </div >
            <footer className="page-footer">
                {footer}
            </footer>
        </div >
    );
}

export default Page;
