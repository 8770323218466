import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../graphql/client";
import { GET_FOUND_EGGS } from "../../graphql/queries";
import type { RootState } from "../store";

export interface FoundEgg {
  location?: { x: number; y: number };
  foundBy?: { username: string; color?: string; _id: string };
  foundTimeStamp?: number;
  points?: number;
}
/**
 * The initial state for the EggsFoundSlice
 */
const initialState: FoundEgg[] = [];

/**
 * The EggsFoundSlice in the redux state
 */
export const FoundEggsSlice = createSlice({
  name: "foundeggs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFoundEggs.fulfilled, (state, action) => {
      const data = action.payload as FoundEgg[];
      if (data && data.length > 0) {
        return data;
      }
      return [];
    });
  },
});

/**
 * Selector for getting the foundEggs data;
 */
export const selectFoundEggs = (state: RootState) => state["foundeggs"];

/**
 * Thunk function for fething the players data
 */
export const fetchFoundEggs = createAsyncThunk(
  "eggs/fetch-found-eggs",
  async () => {
    const { data } = await client.query({
      query: GET_FOUND_EGGS,
      variables: {},
      fetchPolicy: "no-cache",
    });
    return data.foundEggs;
  }
);

export default FoundEggsSlice.reducer;
