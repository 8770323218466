
import './EggHuntPage.scss';
import EggLog from '../components/EggLog/EggLog';
import EggMap from '../components/EggMap/EggMap';
import EggRanking from '../components/EggRanking/EggRanking';
import SubmitEgg from '../components/SubmitEgg/SubmitEgg';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { fetchPlayers, selectPlayers } from "../state/players/PlayersSlice"
import { fetchFoundEggs } from '../state/foundEggs/FoundEggsSlice';
import { fetchNotFoundEgg } from '../state/notFoundEgg/NotFoundEggSlice';
import { selectCurrentplayer } from '../state/currentplayer/CurrentplayersSlice';
import Page from '../components/Page/Page';

function EggHuntPage() {
    const dispatch = useAppDispatch();
    const currentPlayer = useAppSelector(selectCurrentplayer);
    const players = useAppSelector(selectPlayers);
    const currentPlayerUsername = currentPlayer?.username;
    const currentPlayerIndex = players.findIndex(({ username }) => (username === currentPlayerUsername));
    const currentPlayerData = players[currentPlayerIndex];

    const runEvery15Seconds = async () => {
        dispatch(fetchPlayers());
        dispatch(fetchFoundEggs());
    }

    useEffect(() => {
        runEvery15Seconds();
        const interval = setInterval(() => {
            runEvery15Seconds();
        }, 15000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const runEvery60Seconds = async () => {
        dispatch(fetchNotFoundEgg());
    }

    useEffect(() => {
        const interval = setInterval(() => {
            runEvery60Seconds();
        }, 60000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])


    return (
        <Page className="egg-hunt-page" header={
            <div className="player-info">
                <span className='player-color' style={{ ['--player-color' as string]: currentPlayerData?.color }}></span> 
                 <span className='player-username'>{currentPlayerData?.username}</span><span className='number-of-eggs'>{currentPlayerData?.eggs} Ei(eren) </span>
                 <span className='number-of-points'>{currentPlayerData?.points} Punten</span>
            </div >
        }
            footer={
                <div className="player-info">
                    <EggLog />
                </div >
            }
        >
            <div className='page-column'>
                <SubmitEgg />
                <EggRanking />
            </div>
            <div className='page-column'>
                <EggMap />
            </div>
        </Page>
    );
}

export default EggHuntPage;
