import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../graphql/client";
import { REGISTER_PLAYER } from "../../graphql/queries";
import type { RootState } from "../store";

interface Currentplayer {
  _id?: string;
  username?: string;
  admin?: boolean;
}
/**
 * The initial state for the PlayersSlice
 */
const initialState: Currentplayer = {};

/**
 * The CurrentplayerSlice in the redux state
 */
export const CurrentplayerSlice = createSlice({
  name: "currentplayer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Add the playersData to the state when the fetch request is completed
     */
    builder.addCase(registerPlayer.fulfilled, (state, action) => {
      return (action.payload || {}) as Currentplayer;
    });
  },
});

/**
 * Selector for getting the players data;
 */
export const selectCurrentplayer = (state: RootState) => state.currentplayer;

/**
 * Thunk function for fething the players data
 */
export const registerPlayer = createAsyncThunk(
  "currentplayer/register",
  async (username: string) => {
    if (username === "mark-admin-80f1ca55b") {
      return { _id: "SJKhIAKYB", username, admin: true } as Currentplayer;
    } else if (username.length > 0) {
      const {
        data: { registerPlayer },
      } = await client.mutate({
        mutation: REGISTER_PLAYER,
        variables: { input: { username } },
      });

      return { _id: registerPlayer, username, admin: false } as Currentplayer;
    }
  }
);

export default CurrentplayerSlice.reducer;
