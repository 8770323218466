import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../graphql/client";
import { GET_ALL_EGGS, UPADATE_EGG } from "../../graphql/queries";
import type { RootState } from "../store";

interface Egg {
  _id: string;
  code?: string;
  location?: { x: number; y: number };
  foundBy?: { username: string; _id: string };
  foundTimeStamp?: number;
  points?: number;
  number?: number;
}
/**
 * The initial state for the EggsFoundSlice
 */
const initialState: Egg[] = [];

/**
 * The EggsFoundSlice in the redux state
 */
export const EggsSlice = createSlice({
  name: "eggs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEggs.fulfilled, (state, action) => {
        const data = action.payload as Egg[];
        if (data && data.length > 0) {
          return data;
        }
        return [];
      })
      .addCase(updateEgg.fulfilled, (state, action) => {
        if (action.payload as boolean) {
        }
      });
  },
});

/**
 * Selector for getting the foundEggs data;
 */
export const selectEggs = (state: RootState) => state["eggs"];

/**
 * Thunk function for fething the players data
 */
export const fetchAllEggs = createAsyncThunk("eggs/fetch-all-egg", async () => {
  const { data } = await client.query({
    query: GET_ALL_EGGS,
    variables: {},
    fetchPolicy: "no-cache",
  });
  return data.allEggs;
});

/**
 * Thunk function for fething the players data
 */
export const updateEgg = createAsyncThunk(
  "eggs/update",
  async ({
    _id,
    playerId,
    points,
    pictureURL,
    x,
    y,
  }: {
    _id: string;
    playerId: string;
    points?: number;
    pictureURL?: string;
    x?: number;
    y?: number;
  }) => {
    const {
      data: { updateEgg },
    } = await client.mutate({
      mutation: UPADATE_EGG,
      variables: { eggId: _id, input: { playerId, points, pictureURL, x, y } },
    });

    return updateEgg;
  }
);

export default EggsSlice.reducer;
