import { gql } from '@apollo/client';

export const EGG_FOUND = gql`   
    mutation EggFound($input: EggCode!) {
  eggFound(input: $input)
}
`;

export const GET_ALL_EGGS = gql`   
    query AllEggs {
  allEggs {
    _id
    code
    points
    foundBy {
      username
      _id
    }
    foundTimeStamp
    pictureURL
    location {
      x
      y
    }
    number
  }
}
`;

export const GET_FOUND_EGGS = gql`   
    query FoundEggs {
  foundEggs {
    foundBy {
      username
      _id
      color
    }
    foundTimeStamp
    location {
      x
      y
    }
    points
  }
}
`;

export const GET_NOT_FOUND_EGG = gql`   
   query NotFoundEgg {
  notFoundEgg {
    _id
    points
    pictureURL
    locationIndication {
      x
      y
      size
    }
  }
}
`;

export const GET_PLAYERS = gql`   
   query Players {
  players {
    _id
    username
    admin
    eggs
    points
    color
  }
}
`;

export const REGISTER_PLAYER = gql`   
   mutation RegisterPlayer($input: PlayerInput!) {
  registerPlayer(input: $input)
}
`;

export const UPADATE_EGG = gql`   
mutation UpdateEgg($eggId: ID!, $input: EggInput!) {
  updateEgg(eggId: $eggId, input: $input)
}
`;

