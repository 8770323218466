
import React, { useEffect, useState } from 'react'
import './SubmitEgg.scss';
import { useEggFound } from '../../graphql/hooks';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { selectCurrentplayer } from '../../state/currentplayer/CurrentplayersSlice';
import confetti from 'canvas-confetti';
import { fetchPlayers } from '../../state/players/PlayersSlice';

const confettiAnmiation = () => {
    var count = 200;
    var defaults = {
        origin: { y: 0.7 }
    };

    const fire = (particleRatio: number, opts: any) => {
        confetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
        }));
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    });
}

function SubmitEgg(): ReturnType<React.FC> {

    const dispatch = useAppDispatch();
    const [value, setValue] = useState("");
    const [newPoints, setNewPoints] = useState(0);


    useEffect(() => {
        if (newPoints > 0) {
            setTimeout(() => {
                setNewPoints(0);
            }, 3000);
        }
    }, [newPoints]);


    const { eggFound } = useEggFound();
    const currentPlayer = useAppSelector(selectCurrentplayer);
    const playerId = currentPlayer._id;
    const _submit = async () => {
        let result = 0;
        try {
            result = await eggFound(playerId, value);
            await dispatch(fetchPlayers());
        } catch (e) {

            console.log("e", e);
        }
        if (result > 0) {
            confettiAnmiation();
            setNewPoints(result)
            setValue("");
        }
    }

    return (
        <div className="submit-egg">
            <form onSubmit={async () => _submit()}>
                <label>Ei gevonden?<br />Verzilver de punten</label>
                <input type="text" value={value} onChange={e => setValue(e.target.value)} />
                <span className='button' onClick={async () => _submit()}>Verzilver</span>
            </form>
            <div className='new-points-container'>
                {newPoints > 0 &&
                    <span className='new-points'>+{newPoints}</span>
                }
            </div>
        </div >);
};

export default SubmitEgg;

