import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EggHuntAdminRegisterEggsPage from "../pages/EggHuntAdminRegisterEggs";
import EggHuntPage from "../pages/EggHuntPage";
import RegisterPage from "../pages/RegisterPage";
import { useAppSelector } from "../state/hooks";
import { selectCurrentplayer } from "../state/currentplayer/CurrentplayersSlice";
import EggHuntDashboard from "../pages/EggHuntAdminDashboard";
import EggHuntAdminMapPage from "../pages/EggHuntAdminMap";
import EggHuntAdminMenu from "../pages/EggHuntAdminMenu";
import EggHuntThankYouPage from "../pages/EggHuntThankYouPage";
/**
 * The component that contains the routes in the application
 */
const ApplicationRouter: React.FC = () => {
  const currentPlayer = useAppSelector(selectCurrentplayer);

  const renderAdminRegisterEggsPage = () => {
    if (currentPlayer.admin) {
      return <EggHuntAdminRegisterEggsPage />;
    }
    if (!!currentPlayer._id) {
      return <EggHuntPage />;
    }
    return <RegisterPage />;
  };
  const renderAdminDashboardPage = () => {
    if (currentPlayer.admin) {
      return <EggHuntDashboard />;
    }
    if (!!currentPlayer._id) {
      return <EggHuntPage />;
    }
    return <RegisterPage />;
  };
  const renderAdminMapPage = () => {
    if (currentPlayer.admin) {
      return <EggHuntAdminMapPage />;
    }
    if (!!currentPlayer._id) {
      return <EggHuntPage />;
    }
    return <RegisterPage />;
  };
  // const renderDashboardPage = () => {
  //   if (currentPlayer.admin) {
  //     return <EggHuntAdminMenu />;
  //   }
  //   if (!!currentPlayer._id) {
  //     return <EggHuntPage />;
  //   }
  //   return <RegisterPage />;
  // };
  const renderThankYouPage = () => {
    return <EggHuntThankYouPage />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/registerEggs" element={renderAdminRegisterEggsPage()} />
        <Route path="/map" element={renderAdminMapPage()} />
        <Route path="/dashboard" element={renderAdminDashboardPage()} />
        <Route path="*" element={renderThankYouPage()} />
      </Routes>
    </BrowserRouter>
  );
};

export default ApplicationRouter;
