
import React from 'react'
import './EggMapAdmin.scss';

interface eggAdminProps {
    x: number | undefined
    y: number | undefined
    updateEgg: Function
}

function EggMapAdmin(props: eggAdminProps): ReturnType<React.FC<eggAdminProps>> {

    const { x = -50, y = -50, updateEgg } = props;
    return (
        <div className="egg-map-admin">
            <img src="./map.png" onClick={(e: React.MouseEvent) => {
                const element = e.target as HTMLElement;
                const rect = element.getBoundingClientRect();
                const x = Math.round(e.clientX - rect.left); //x position within the element.
                const y = Math.round(e.clientY - rect.top);  //y position within the element.
                updateEgg({ x, y })
            }} alt="" />
            <span className='marker' style={{ ['--marker-x' as string]: x + "px", ['--marker-y' as string]: y + "px" }} />
        </div>);
};

export default EggMapAdmin;

