import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../graphql/client";
import { GET_NOT_FOUND_EGG } from "../../graphql/queries";
import type { RootState } from "../store";

interface NotFoundEgg {
  locationIndication?: { x: number; y: number; size: number };
  points?: number;
}
/**
 * The initial state for the EggsFoundSlice
 */
const initialState: NotFoundEgg = {};

/**
 * The EggsFoundSlice in the redux state
 */
export const NotFoundEggSlice = createSlice({
  name: "notfoundegg",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotFoundEgg.fulfilled, (state, action) => {
      const data = (action.payload || {}) as NotFoundEgg;
      return data;
    });
  },
});

/**
 * Selector for getting the foundEggs data;
 */
export const selectNotFoundEgg = (state: RootState) => state["notfoundegg"];

/**
 * Thunk function for fething the players data
 */
export const fetchNotFoundEgg = createAsyncThunk(
  "notfoundegg/fetch",
  async () => {
    const { data } = await client.query({
      query: GET_NOT_FOUND_EGG,
      variables: {},
      fetchPolicy: "no-cache",
    });
    return data.notFoundEgg;
  }
);

export default NotFoundEggSlice.reducer;
