import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import eggsReducer from "./eggs/EggsSlice";
import playersReducder from "./players/PlayersSlice";
import currentPlayerReducer from "./currentplayer/CurrentplayersSlice";
import notfoundeggReducer from "./notFoundEgg/NotFoundEggSlice";
import foundeggsReducer from "./foundEggs/FoundEggsSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
/**
 * Redux persist configuration
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["currentplayer"],
};

/**
 * Combine the reducers
 */
const reducers = combineReducers({
  eggs: eggsReducer,
  notfoundegg: notfoundeggReducer,
  foundeggs: foundeggsReducer,
  players: playersReducder,
  currentplayer: currentPlayerReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

/**
 * Configure the redux store
 */
export const store = configureStore({
  reducer: persistedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
