import "./EggHuntThankYouPage.scss";
import Page from "../components/Page/Page";

function EggHuntThankYouPage() {
  return (
    <Page className="egg-hunt-thank-you-page">
      <h1>Bedankt voor het meespelen</h1>
      <p>Tot volgend jaar!</p>
      <p>Wie wordt de opvolger van Esther?</p>
      <img src="./ranking2024.png" alt="" />
    </Page>
  );
}

export default EggHuntThankYouPage;
