import './App.scss';
import ApplicationRouter from './router/Router';
import { ApolloProvider } from '@apollo/client';
import client from './graphql/client';

function App() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <ApolloProvider client={client}>
      <div className="app">
        <ApplicationRouter />
      </div>
    </ApolloProvider>
  );
}

export default App;
