import "./EggHuntAdminDashboard.scss";
import EggMapAdmin from "../components/EggMapAdmin/EggMapAdmin";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { useEffect, useState } from "react";
import { fetchAllEggs, selectEggs } from "../state/eggs/EggsSlice";
import { fetchPlayers } from "../state/players/PlayersSlice";
import Page from "../components/Page/Page";

enum Filter {
  ALL = "ALL",
  FOUND = "FOUND",
  NOTFOUND = "NOTFOUND",
}
function EggHuntAdminDashboard() {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState(Filter.ALL);
  const [currentEggIndex, setCurrentEggIndex] = useState(0);
  const allEggs = useAppSelector(selectEggs);

  const runEvery15Seconds = async () => {
    dispatch(fetchPlayers());
    dispatch(fetchAllEggs());
  };

  useEffect(() => {
    runEvery15Seconds();
    const interval = setInterval(() => {
      runEvery15Seconds();
    }, 15000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  let eggs;
  switch (filter) {
    case Filter.ALL:
      eggs = allEggs;
      break;
    case Filter.NOTFOUND:
      eggs = allEggs.filter(
        (egg) => egg.foundBy === null || egg.foundBy === undefined
      );
      break;
    case Filter.FOUND:
      eggs = allEggs.filter((egg) => !!egg.foundBy);
      break;
    default:
      eggs = allEggs;
      break;
  }

  const currentEgg = eggs[currentEggIndex];

  return (
    <Page
      className="admin-dashboard"
      header={
        <div className="button-bar">
          <span
            onClick={() => {
              setFilter(Filter.ALL);
              setCurrentEggIndex(0);
            }}
          >
            {" "}
            All{" "}
          </span>
          <span
            onClick={() => {
              setFilter(Filter.FOUND);
              setCurrentEggIndex(0);
            }}
          >
            {" "}
            Found{" "}
          </span>
          <span
            onClick={() => {
              setFilter(Filter.NOTFOUND);
              setCurrentEggIndex(0);
            }}
          >
            {" "}
            Not Found{" "}
          </span>
        </div>
      }
      footer={
        <div className="navigation-buttons">
          <span
            onClick={() => {
              const newIndex =
                currentEggIndex === 0 ? eggs.length - 1 : currentEggIndex - 1;
              setCurrentEggIndex(newIndex);
            }}
          >
            {" "}
            {"<"}{" "}
          </span>
          <label>{currentEgg?.number}</label>-<label>{currentEgg?.code}</label>
          <span
            onClick={() => {
              const newIndex =
                currentEggIndex === eggs.length - 1 ? 0 : currentEggIndex + 1;
              setCurrentEggIndex(newIndex);
            }}
          >
            {" "}
            {">"}{" "}
          </span>
        </div>
      }
    >
      <div className="page-column">
        <EggMapAdmin
          x={currentEgg?.location?.x}
          y={currentEgg?.location?.y}
          updateEgg={() => {}}
        />
      </div>
      <div className="page-column">
        <p className="filter-text">
          {`${currentEggIndex + 1} / ${eggs.length}`}
        </p>
        <p className="found">
          {` ${
            currentEgg?.foundBy
              ? `Gevonden door: ${currentEgg?.foundBy?.username}`
              : ""
          }`}
        </p>
        <div className="points-bar">
          {[20, 30, 40, 50, 100, 150, 200, 250, 300].map((_points) => (
            <span
              className={`${currentEgg?.points === _points ? "selected" : ""}`}
            >
              {" "}
              {_points}{" "}
            </span>
          ))}
        </div>
      </div>
    </Page>
  );
}

export default EggHuntAdminDashboard;
