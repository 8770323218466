
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient as createWsClient } from 'graphql-ws';

const httpLink = new HttpLink({
  uri: process.env.SERVERHTTP || "https://pasen-backend.eu-central-1.elasticbeanstalk.com/graphql",
});

const wsLink = new GraphQLWsLink(createWsClient({
  url: process.env.SERVERWS || "ws://pasen-backend.eu-central-1.elasticbeanstalk.com/graphql",
  connectionParams: () => ({}),
}));

function isSubscription({ query }) {
  const definition = getMainDefinition(query);
  return definition.kind === Kind.OPERATION_DEFINITION
    && definition.operation === OperationTypeNode.SUBSCRIPTION;
}

export const client = new ApolloClient({
  link: split(isSubscription, wsLink, httpLink),
  cache: new InMemoryCache(),
});

export default client;