import { useMutation } from '@apollo/client';
import { EGG_FOUND } from './queries';

export function useEggFound() {
    const [mutate] = useMutation(EGG_FOUND);
    return {
        eggFound: async (playerId, code) => {
            const { data: { eggFound } } = await mutate({
                variables: { input: { code, playerId } },
            });
            return eggFound;
        },
    };
}