import "./EggHuntAdminRegisterEggs.scss";
import EggMapAdmin from "../components/EggMapAdmin/EggMapAdmin";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { useEffect, useState } from "react";
import { fetchAllEggs, selectEggs, updateEgg } from "../state/eggs/EggsSlice";
import { fetchPlayers } from "../state/players/PlayersSlice";
import { selectCurrentplayer } from "../state/currentplayer/CurrentplayersSlice";
import Page from "../components/Page/Page";

function EggHuntAdminRegisterEggs() {
  const dispatch = useAppDispatch();
  const [currentEggIndex, setCurrentEggIndex] = useState(0);
  const currentPlayer = useAppSelector(selectCurrentplayer);
  const allEggs = useAppSelector(selectEggs);

  const runEvery15Seconds = async () => {
    dispatch(fetchPlayers());
    dispatch(fetchAllEggs());
  };

  useEffect(() => {
    runEvery15Seconds();
    const interval = setInterval(() => {
      runEvery15Seconds();
    }, 15000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const currentEgg = allEggs[currentEggIndex];
  const _updateEgg = async ({
    points = currentEgg?.points,
    x = currentEgg?.location?.x,
    y = currentEgg?.location?.y,
  }: {
    points?: number;
    x?: number;
    y?: number;
  }) => {
    const updatedEgg = {
      playerId: currentPlayer?._id || "",
      _id: currentEgg._id,
      points: points as number,
      x: x as number,
      y: y as number,
    };
    await dispatch(updateEgg(updatedEgg));
    await dispatch(fetchAllEggs());
  };

  return (
    <Page
      className="register-eggs"
      header={
        <p className="filter-text">
          {`${currentEggIndex + 1} / ${allEggs.length}`}
        </p>
      }
      footer={
        <div className="navigation-buttons">
          <span
            onClick={() => {
              const newIndex =
                currentEggIndex === 0
                  ? allEggs.length - 1
                  : currentEggIndex - 1;
              setCurrentEggIndex(newIndex);
            }}
          >
            {" "}
            {"<"}{" "}
          </span>
          <label>{currentEgg?.number}</label>-<label>{currentEgg?.code}</label>
          <span
            onClick={() => {
              const newIndex =
                currentEggIndex === allEggs.length - 1
                  ? 0
                  : currentEggIndex + 1;
              setCurrentEggIndex(newIndex);
            }}
          >
            {" "}
            {">"}{" "}
          </span>
        </div>
      }
    >
      <div className="page-column">
        <EggMapAdmin
          x={currentEgg?.location?.x}
          y={currentEgg?.location?.y}
          updateEgg={_updateEgg}
        />
      </div>
      <div className="page-column">
        <div className="points-bar">
          {[20, 30, 40, 50, 100, 150, 200, 250, 300].map((_points) => (
            <span
              className={`${currentEgg?.points === _points ? "selected" : ""}`}
              onClick={() => {
                _updateEgg({ points: _points });
              }}
            >
              {" "}
              {_points}{" "}
            </span>
          ))}
        </div>
      </div>
    </Page>
  );
}

export default EggHuntAdminRegisterEggs;
