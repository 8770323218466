
import React from 'react'
import './EggMap.scss';
import { useAppSelector } from '../../state/hooks';
import { selectFoundEggs } from '../../state/foundEggs/FoundEggsSlice';
import { selectNotFoundEgg } from '../../state/notFoundEgg/NotFoundEggSlice';


function EggMap(): ReturnType<React.FC> {

    const foundEggs = useAppSelector(selectFoundEggs);
    const hiddenEgg = useAppSelector(selectNotFoundEgg);
    const { locationIndication, points: hiddenEggPoints } = hiddenEgg;
    const { x: hiddenEggX = -99999, y: hiddenEggY = -99999, size: hiddenEggSize = 20 } = locationIndication || {};
    return (
        <div className="egg-map">
            <img src="./map.png" alt="" />
            {foundEggs?.length > 0 && foundEggs.map(foundEgg => {
                const { foundBy, location } = foundEgg;
                const { x = -99999, y = -99999 } = location || {};
                const { color } = foundBy || {};
                return (<span className='marker' style={{ ['--marker-x' as string]: x + "px", ['--marker-y' as string]: y + "px", ['--marker-color' as string]: color }} />)
            })
            }
            {hiddenEgg &&
                (<span className='marker' style={{ ['--marker-x' as string]: hiddenEggX + "px", ['--marker-y' as string]: hiddenEggY + "px", ['--marker-color' as string]: "black", ['--marker-size' as string]: (2 * hiddenEggSize) + "px" }}><span><h3>?</h3>{hiddenEggPoints} pnt</span></span>)

            }
        </div>);
};


export default EggMap;


