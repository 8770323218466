
import React from 'react'
import './EggRanking.scss';
import { useAppSelector } from "../../state/hooks";
import { selectPlayers } from '../../state/players/PlayersSlice';

function EggRanking(): ReturnType<React.FC> {
    const playerData = useAppSelector(selectPlayers)
    return (
        <div className="egg-ranking">
            <ul>
                {playerData.map((player, index) => {
                    const { username, points, eggs, color } = player;
                    return (
                        <li><span className="player-rank">{index + 1}</span><span className="player-color" style={{ ['--player-color' as string]: color }}></span><span>{username}</span><span>{eggs}</span><span>{points}</span></li>
                    )
                })}
            </ul>
        </div>);
};

export default EggRanking;

