
import './EggHuntAdminMap.scss';
import EggLog from '../components/EggLog/EggLog';
import EggMap from '../components/EggMap/EggMap';
import EggRanking from '../components/EggRanking/EggRanking';
import { useEffect } from 'react';
import { useAppDispatch } from '../state/hooks';
import { fetchPlayers } from "../state/players/PlayersSlice"
import { fetchFoundEggs } from '../state/foundEggs/FoundEggsSlice';
import { fetchNotFoundEgg } from '../state/notFoundEgg/NotFoundEggSlice';
import Page from '../components/Page/Page';

function EggHuntAdminMapPage() {
    const dispatch = useAppDispatch();

    const runEvery15Seconds = async () => {
        dispatch(fetchPlayers());
        dispatch(fetchFoundEggs());
    }

    useEffect(() => {
        runEvery15Seconds();
        const interval = setInterval(() => {
            runEvery15Seconds();
        }, 15000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const runEvery60Seconds = async () => {
        dispatch(fetchNotFoundEgg());
    }

    useEffect(() => {
        const interval = setInterval(() => {
            runEvery60Seconds();
        }, 60000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])


    return (
        <Page className='egg-hunt-admin-map'
            footer={
                <EggLog />
            }
        >
            <div className='page-column'>
                <EggRanking />
            </div>
            <div className='page-column'>
                <EggMap />
            </div>
        </Page>
    );
}

export default EggHuntAdminMapPage;
