
import { useState } from 'react';
import { registerPlayer } from '../state/currentplayer/CurrentplayersSlice';
import { useAppDispatch } from '../state/hooks';
import './RegisterPage.scss';
import Page from '../components/Page/Page';

function RegisterPage() {
    const dispatch = useAppDispatch();
    const [value, setValue] = useState("");

    return (
        <Page className="register-page">
            <h1>Welkom</h1>
            <p>Dit is de paas-app</p>
            <div className='form'>
                <label>Vul hier je naam in en speel mee:</label>
                <input type="text" value={value} onChange={e => setValue(e.target.value)} />
                <span className='button' onClick={async () => {

                    dispatch(registerPlayer(value));

                }}>Speel mee!</span>
            </div>
        </Page>
    );
}

export default RegisterPage;
