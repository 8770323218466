
import React from 'react'
import './EggLog.scss';
import { FoundEgg, selectFoundEggs } from '../../state/foundEggs/FoundEggsSlice';
import { useAppSelector } from '../../state/hooks';
import Ticker from 'react-awesome-ticker';


const getMessage = (foundEgg: FoundEgg) => {
    const secondsAgo = ((Date.now() - (foundEgg?.foundTimeStamp || 0)) / 1000);
    let timeText = "";
    if (secondsAgo < 30) {
        timeText = "zojuist";
    } else if (secondsAgo < 45) {
        timeText = "30 seconden geleden";
    } else if (secondsAgo < 90) {
        timeText = "1 minuut geleden";
    } else {
        timeText = `${Math.round(secondsAgo / 60)} minuten geleden`;
    }
    return <p className='marquee-message'><span>&nbsp;▪&nbsp;</span>{`${foundEgg?.foundBy?.username} vond ${timeText} een ei`}&nbsp;<span className='green-text'>{`+${foundEgg.points}`}</span><span>&nbsp;▪&nbsp;</span></p>;
}

function EggLog(): ReturnType<React.FC> {
    const foundEggs = useAppSelector(selectFoundEggs);

    const sortedFoundEggs = [...foundEggs].sort((a, b) => {
        if (b?.foundTimeStamp && a?.foundTimeStamp) {
            return (b.foundTimeStamp - a.foundTimeStamp);
        }
        return 0;
    });
    const last5 = sortedFoundEggs.slice(0, 5).reverse();
    return (
        <div className='egg-log'>
            {(last5.length > 0) && last5.map(foundEgg => {
                return getMessage(foundEgg);
            })}
            {(last5.length === 0) && (<p>Succes!!</p>)}
        </div>);
};

export default EggLog;

